import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GlobalLoaderService {
  showLoading = new BehaviorSubject(false)
  private _showLoading$ = this.showLoading.asObservable();
  constructor() { }


  get showLoading$(): Observable<boolean> {
    return this._showLoading$;
  }

  show(){
    this.showLoading.next(true)
  }

  hide(){
    this.showLoading.next(false)
  }
}
